@import './index-tablet.module.scss';
@import './index-mobile.module.scss';

.page {
  max-width: 1440px;
  margin: 0 auto;
  .banner {
    height: 974px;
    background-image: url('/img/bg1.png');
    background-size: 100% 100%;
    background-position: 74px 0;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
    .logo {
      position: absolute;
      top: 32px;
      left: 136px;
      cursor: pointer;
    }
    .bannerImg {
      position: absolute;
      top: 180px;
      left: 0;
      width: 750px;
    }
    .container {
      margin-top: 300px;
      margin-right: 80px;
      text-align: right;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .title {
        color: #fff;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Poppins;
        font-size: 86px;
        font-style: normal;
        font-weight: 600;
        line-height: 106px;
      }
      .subTitle {
        color: rgba(255, 255, 255, 0.8);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 187.5% */
        margin-top: 30px;
        width: 500px;
        text-align: left;
        margin-right: 80px;
        position: relative;
      }
    }
  }

  .paragraph1 {
    display: flex;
    justify-content: space-between;
    gap: 80px;
    align-items: center;
    padding: 120px 74px;
    border-bottom: 1px solid #d6d6d6;
  }

  .paragraph2 {
    display: flex;
    justify-content: space-between;
    gap: 80px;
    align-items: flex-start;
    padding: 120px 74px 0 0;
  }

  .paragraph3 {
    display: flex;
    justify-content: space-between;
    gap: 80px;
    align-items: center;
    padding: 62px 0 62px 74px;
    background-image: linear-gradient(to right, black, black);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: -74px 0;
    margin-top: -50px;
  }

  .paragraph4 {
    padding: 90px 70px;
    .header {
      display: flex;
      justify-content: space-between;
    }
    .body {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 32px;
      margin-top: 64px;
    }

    .termItem {
      width: 400px;

      .itemHeader {
        position: relative;
      }
      .itemBody {
        border: 1px solid #d6d6d6;
        border-top: 0;
        background: #fff;
        padding: 28px 32px;
        height: 270px;
      }
      .itemTitle {
        color: #0d0d0d;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .itemSubTitle {
        color: #0d0d0d;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 175% */
        margin-top: 10px;
      }
      .itemTag {
        position: absolute;
        padding: 14px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: #fff;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        top: 20px;
        left: 20px;
        background: #dd473f;
      }
      .itemPic {
        background-color: #e7e7e7;
      }
    }
  }

  .paragraph5 {
    min-height: 740px;
    background-image: url('/img/pic11.png');
    background-size: 100% 100%;
    background-position: 74px 0;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
    padding: 120px 560px 120px 188px;
  }

  .paragraph6 {
    background: #0d0d0d;
    margin-top: -220px;
    height: 670px;
    &::before {
      content: '';
      overflow: hidden;
      display: block;
    }
    .sequence {
      margin-top: 340px;
      margin-left: 74px;
    }
    .fs56 {
      margin-left: 74px;
    }
    .innovations {
      margin: 70px auto 0;
      margin-left: 74px;
      margin-right: 74px;
      display: flex;
      justify-content: center;
    }

    .innovationItem {
      width: 322px;
      height: 185px;
      background: #fff;
      border-right: 1px solid #d6d6d6;
      border-bottom: 1px solid #d6d6d6;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50%;
      &:first-child {
        border-left: 1px solid #d6d6d6;
      }
    }

    .innovationItem1 {
      width: 322px;
      height: 185px;
      background: #fff;
      border-right: 1px solid #d6d6d6;
      border-bottom: 1px solid #d6d6d6;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 25%;
    }
  }

  .paragraph7 {
    height: 370px;
    background-image: url('/img/pic20.png');
    background-size: 100% 100%;
    background-position: -74px 0;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
    padding: 120px 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 400px;
  }

  .footer {
    background-color: #000;
    margin-top: -180px;
    padding-top: 260px;
    padding-left: 70px;
    padding-right: 70px;
    color: #fff;
    .row1 {
      display: flex;
      justify-content: space-between;
    }
    .column2 {
      min-width: 350px;
      margin-top: 12px;
      .logo {
        display: none;
      }
    }
    .row2 {
      border-top: 1px solid #68686d;
      padding-top: 30px;
      margin-top: 50px;
      padding-bottom: 50px;
    }
    .copyright {
      display: none;
    }
    .text {
      color: rgba(255, 255, 255, 0.4);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 187.5% */
      margin-top: 35px;
    }
    .social {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 30px;
      svg {
        cursor: pointer;
      }
    }
    .contactInformation {
      display: flex;
      gap: 10px;
      margin-top: 30px;
    }
  }

  .sequence {
    color: #dd473f;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .fs56 {
    color: #0d0d0d;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-size: 56px;
    font-style: normal;
    font-weight: 600;
    line-height: 70px; /* 125% */
    margin-top: 10px;
  }

  .fs16 {
    color: #0d0d0d;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 187.5% */
  }

  .button1 {
    white-space: nowrap;
    padding: 22px 36px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #fff;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: 2px solid #fff;
    display: inline-block;
    margin-top: 40px;
    cursor: pointer;
  }

  .button2 {
    padding: 20px 26px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: 2px solid #000;
    display: inline-block;
    margin-top: 40px;
    display: flex;
    align-items: center;
    height: 71px;
    white-space: nowrap;
    cursor: pointer;
  }

  .button3 {
    display: flex;
    padding: 22px 36px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #fff;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: #dd473f;
    margin-top: 20px;
    margin-right: 370px;
    cursor: pointer;
  }

  .button4 {
    display: flex;
    white-space: nowrap;
    padding: 22px 36px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #dd473f;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: #fff;
    margin-right: 60px;
    cursor: pointer;
  }

  .inputWrap {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 40px;
  }

  .input1 {
    display: flex;
    width: 100%;
    padding: 22px 30px;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    color: #fff;
    border: 2px solid #fff;
    background: #dd473f;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    outline: none;
  }
}

/* 小于1024px tablet */
@media screen and (max-width: 1024px) {
  @include tablet();
}
/* 小于480px mobile */
@media screen and (max-width: 480px) {
  @include mobile();
}
