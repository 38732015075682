@mixin tablet {
  .page {
    max-width: 1440px;
    margin: 0 auto;
    overflow: hidden;
    .banner {
      margin-left: 0;
      background-image: url('/img/bg1.png');
      background-size: 100% 100%;
      background-position: 0 0;
      background-repeat: no-repeat;
      position: relative;
      overflow: hidden;
      padding-bottom: 50px;
      .logo {
        position: absolute;
        top: 32px;
        left: 32px;
        cursor: pointer;
      }
      .bannerImg {
        position: absolute;
        top: 180px;
        left: 0;
        width: 750px;
      }
      .container {
        margin-top: 100px;
        margin-right: 0;
        margin-left: 0;
        padding: 24px;
        text-align: left;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        .container_left,
        .container_right {
          width: 100% !important;
        }
        .title {
          color: var(--White, #fff);
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Poppins;
          font-size: 32px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 48px */
        }
        .subTitle {
          color: rgba(255, 255, 255, 1);
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px; /* 187.5% */
          margin-top: 30px;
          width: 500px;
          text-align: center;
          margin-right: auto;
          margin-left: auto;
          position: relative;
        }
      }
    }

    .paragraph1 {
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 24px;
      align-items: center;
      padding: 24px 24px;
      border-bottom: 0;
      margin-top: 0;
      .contactWrap {
        margin-top: 0;
        padding: 48px;
      }
    }

    .paragraph2 {
      display: flex;
      justify-content: space-between;
      gap: 60px;
      align-items: flex-start;
      padding: 60px 24px;
      .paragraph2_left,
      .paragraph2_right {
        width: 50% !important;
      }
    }

    .paragraph3 {
      display: flex;
      justify-content: space-between;
      gap: 80px;
      align-items: center;
      padding: 62px 24px;
      background-image: linear-gradient(to right, black, black);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: 0 0;
      margin-top: -20px;
      flex-direction: column-reverse;
      .paragraph3_left,
      .paragraph3_right {
        width: 100% !important;
      }
    }

    .paragraph4 {
      padding: 24px;
      .header {
        display: flex;
        justify-content: space-between;
      }
      .body {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 32px;
        margin-top: 64px;
      }

      .termItem {
        width: 400px;

        .itemHeader {
          position: relative;
        }
        .itemBody {
          border: 1px solid #d6d6d6;
          border-top: 0;
          background: #fff;
          padding: 28px 32px;
          height: 270px;
        }
        .itemTitle {
          color: #0d0d0d;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Poppins;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        .itemSubTitle {
          color: #0d0d0d;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px; /* 175% */
          margin-top: 10px;
        }
        .itemTag {
          position: absolute;
          padding: 14px 24px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          color: #fff;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          top: 20px;
          left: 20px;
          background: #dd473f;
        }
        .itemPic {
          background-color: #e7e7e7;
        }
      }
    }

    .paragraph5 {
      height: 500px;
      background-image: url('/img/pic11.png');
      background-size: 200% 100%;
      background-position: 0 0;
      background-repeat: no-repeat;
      position: relative;
      overflow: hidden;
      padding: 24px;
    }

    .paragraph6 {
      background: #0d0d0d;
      margin-top: -220px;
      height: 670px;
      &::before {
        content: '';
        overflow: hidden;
        display: block;
      }
      .sequence {
        margin-top: 260px;
        margin-left: 24px;
      }
      .fs56 {
        margin-left: 24px;
      }
      .innovations {
        margin: 24px auto 0;
        margin-left: 24px;
        margin-right: 24px;
        display: flex;
        justify-content: center;
      }

      .innovationItem {
        width: 322px;
        height: 185px;
        background: #fff;
        border-right: 1px solid #d6d6d6;
        border-bottom: 1px solid #d6d6d6;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50%;
        &:first-child {
          border-left: 1px solid #d6d6d6;
        }
      }

      .innovationItem1 {
        width: 322px;
        height: 185px;
        background: #fff;
        border-right: 1px solid #d6d6d6;
        border-bottom: 1px solid #d6d6d6;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 25%;
      }
    }

    .paragraph7 {
      height: 320px;
      background-image: url('/img/pic20.png');
      background-size: 100% 100%;
      background-position: 0 0;
      background-repeat: no-repeat;
      position: relative;
      overflow: hidden;
      padding: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 0px;
    }

    .footer {
      background-color: #000;
      margin-top: 44px;
      padding-top: 34px;
      padding-left: 24px;
      padding-right: 24px;
      color: #fff;
      .row1 {
        display: flex;
        justify-content: space-between;
        gap: 100px;
        .column1 {
          width: 50%;
        }
      }
      .row2 {
        border-top: 1px solid #68686d;
        padding-top: 30px;
        margin-top: 50px;
        padding-bottom: 50px;
      }
      .text {
        color: rgba(255, 255, 255, 0.4);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 187.5% */
        margin-top: 35px;
        width: 370px;
      }
      .social {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 30px;
        svg {
          cursor: pointer;
        }
      }
      .contactInformation {
        display: flex;
        gap: 10px;
        margin-top: 30px;
      }
    }

    .sequence {
      color: #dd473f;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Poppins;
      font-size: 23px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .fs56 {
      color: #0d0d0d;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Poppins;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 48px */
    }

    .fs16 {
      color: #0d0d0d;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 187.5% */
    }

    .button1 {
      padding: 22px 36px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: #fff;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      border: 2px solid #fff;
      display: inline-block;
      margin-top: 40px;
      cursor: pointer;
    }

    .button2 {
      padding: 20px 26px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: #000;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      border: 2px solid #000;
      display: inline-block;
      margin-top: 40px;
      display: flex;
      align-items: center;
      height: 71px;
      white-space: nowrap;
      cursor: pointer;
    }

    .button3 {
      display: flex;
      padding: 22px 36px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: #fff;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      background: #dd473f;
      margin-top: 20px;
      cursor: pointer;
    }

    .buttonWrap {
    }

    .button4 {
      display: flex;
      padding: 22px 36px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: #dd473f;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      background: #fff;
      margin-right: 0;
      cursor: pointer;
    }

    .inputWrap {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-top: 40px;
    }

    .input1 {
      display: flex;
      padding: 22px 30px;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      border: 2px solid #fff;
      background: #dd473f;
      color: rgba(255, 255, 255, 0.6);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      outline: none;
    }
  }
}
