@mixin mobile {
  .page {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    margin: auto;
    .banner {
      height: 440px;
      background-image: url('/img/bg1.png');
      background-size: 100% 100%;
      background-position: 14px 0;
      background-repeat: no-repeat;
      position: relative;
      overflow: hidden;
      .logo {
        position: absolute;
        top: 14px;
        left: 28px;
        cursor: pointer;
        transform: scale(0.6);
        transform-origin: 0;
      }
      .bannerImg {
        position: absolute;
        top: 80px;
        left: 0;
        width: 750px;
      }
      .container {
        margin-top: 100px;
        margin-right: 0;
        text-align: center;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          color: #fff;
          text-align: center;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Poppins;
          font-size: 30px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%; /* 48px */
        }
        .subTitle {
          color: rgba(255, 255, 255, 1);
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px; /* 187.5% */
          margin-top: 30px;
          width: 100%;
          text-align: center;
          margin-right: auto;
          margin-left: auto;
          position: relative;

          color: #fff;
          text-align: center;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
        }
      }
    }

    .paragraph1 {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      align-items: flex-start;
      padding: 14px 14px;
      border-bottom: 0;
      flex-direction: column;
      .paragraph1_left,
      .paragraph1_right {
        width: 100% !important;
      }
    }

    .paragraph2 {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      gap: 20px;
      align-items: flex-start;
      padding: 14px;
      padding-top: 0;
      .paragraph2_left,
      .paragraph2_right {
        width: 100% !important;
      }
    }

    .paragraph3 {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      align-items: center;
      padding: 14px;
      background-image: linear-gradient(to right, black, black);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: 0 0;
      margin-top: 0;
      flex-direction: column-reverse;
      .paragraph3_left,
      .paragraph3_right {
        width: 100% !important;
      }
    }

    .paragraph4 {
      padding: 14px;
      .header {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 10px;
      }
      .body {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 14px;
        margin-top: 14px;
      }

      .termItem {
        width: 100%;

        .itemHeader {
          position: relative;
        }
        .itemBody {
          border: 1px solid #d6d6d6;
          border-top: 0;
          background: #fff;
          padding: 14px 14px;
          height: auto;
        }
        .itemTitle {
          color: #0d0d0d;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Poppins;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        .itemSubTitle {
          color: #0d0d0d;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px; /* 175% */
          margin-top: 10px;
        }
        .itemTag {
          position: absolute;
          padding: 14px 24px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          color: #fff;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          top: 20px;
          left: 20px;
          background: #dd473f;
        }
        .itemPic {
          background-color: #e7e7e7;
        }
      }
    }

    .paragraph5 {
      height: auto;
      min-height: auto;
      background-image: url('/img/pic11.png');
      background-size: 200% 100%;
      background-position: 0 0;
      background-repeat: no-repeat;
      position: relative;
      overflow: hidden;
      padding: 14px;
    }

    .paragraph6 {
      background: #0d0d0d;
      margin-top: -2px;
      padding-bottom: 14px;
      height: auto;
      &::before {
        content: '';
        overflow: hidden;
        display: block;
      }
      .sequence {
        margin-top: 14px;
        margin-left: 14px;
      }
      .fs56 {
        margin-left: 14px;
      }
      .innovations {
        margin: 14px auto 0;
        margin-left: 14px;
        margin-right: 14px;
        display: flex;
        justify-content: center;
      }

      .innovationItem {
        width: 322px;
        height: 85px;
        background: #fff;
        border-right: 1px solid #d6d6d6;
        border-bottom: 1px solid #d6d6d6;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50%;
        &:first-child {
          border-left: 1px solid #d6d6d6;
        }
      }

      .innovationItem1 {
        width: 322px;
        height: 85px;
        background: #fff;
        border-right: 1px solid #d6d6d6;
        border-bottom: 1px solid #d6d6d6;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 25%;
      }
    }

    .paragraph7 {
      height: auto;
      background-image: url('/img/pic20.png');
      background-size: 100% 100%;
      background-position: 0 0;
      background-repeat: no-repeat;
      position: relative;
      overflow: hidden;
      padding: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      gap: 14px;
      margin-top: 0;
      .fs56 {
        font-size: 18px;
        margin-top: 0;
      }
    }

    .footer {
      background-color: #000;
      margin-top: 44px;
      padding-top: 14px;
      padding-left: 14px;
      padding-right: 14px;
      color: #fff;
      .logo {
        transform: scale(0.5);
      }
      .row1 {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        .column1 {
          width: 100%;
          display: none;
        }
        .column2 {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .logo {
            display: block;
            margin-bottom: 10px;
          }
        }
      }
      .row2 {
        border-top: none;
        padding-top: 0px;
        margin-top: 20px;
        padding-bottom: 0px;
        text-align: center;
      }
      .copyright {
        display: block;
        text-align: center;
      }
      .text {
        color: rgba(255, 255, 255, 0.6);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 187.5% */
        margin-top: 35px;
        width: 100%;
      }
      .social {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 20px;
        padding-bottom: 40px;
        width: 100%;
        justify-content: center;
        svg {
          cursor: pointer;
        }
      }
      .contactInformation {
        display: flex;
        gap: 10px;
        margin-top: 10px;
        text-align: center;
        svg {
          flex-shrink: 0;
        }
      }
    }

    .sequence {
      color: #dd473f;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .fs56 {
      color: #0d0d0d;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 36px */
    }

    .fs16 {
      color: #0d0d0d;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 187.5% */
    }

    .button1 {
      padding: 22px 36px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: #dd473f;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      border: 2px solid #fff;
      display: inline-block;
      margin-top: 40px;
      text-align: center;
      background-color: #fff;
      cursor: pointer;
    }

    .button2 {
      padding: 22px 36px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: #000;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      border: 2px solid #000;
      display: inline-block;
      margin-top: 0;
      display: flex;
      align-items: center;
      height: 71px;
      white-space: nowrap;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }

    .button3 {
      display: flex;
      padding: 12px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: #fff;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      background: #dd473f;
      margin-top: 20px;
      margin-right: auto;
      margin-left: auto;
      cursor: pointer;
      width: 80%;
    }

    .button4 {
      display: flex;
      padding: 22px 36px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: #dd473f;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      background: #fff;
      margin-right: 0;
      cursor: pointer;
    }

    .inputWrap {
      display: flex;
      align-items: stretch;
      flex-direction: column;
      gap: 20px;
      margin-top: 40px;
    }

    .input1 {
      display: flex;
      padding: 22px 30px;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      border: 2px solid #fff;
      background: #dd473f;
      color: rgba(255, 255, 255, 0.6);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      outline: none;
    }
  }
}
