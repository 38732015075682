.snackbar {
  left: 50%;
  width: 90%;
  bottom: 50px;
  word-break: break-word;
}

@media (min-width: 600px) {
  .snackbar {
    left: 50%;
    width: auto;
  }
}
